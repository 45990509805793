.loader {
	position: fixed;
	inset: 0;
	z-index: 3;
	background: rgba(0, 0, 0, 0.6);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	transition: opacity 0.2s;
}

.loaderSvg {
	width: 128px;
}

.loaderText {
	color: white;
	font-weight: bold;
	font-size: 1.8rem;
	margin: 16px;
}
