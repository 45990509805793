.nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	z-index: 2;
	height: 76px;
	background-color: #2c2a29;
	box-shadow: 0 4px 2px -2px gray;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.navLogo {
	height: 60% !important;
	margin-left: 16px !important;
}

.buyBtn {
	margin-right: 16px !important;
}

.loader {
	position: fixed;
	inset: 0;
	z-index: 3;
	background: rgba(0, 0, 0, 0.4);
	display: flex;
	align-items: center;
	justify-content: center;
	transition: opacity 0.2s;
}

.loaderSvg {
	width: 128px;
}

.main {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 76px;
	left: 0;
	z-index: 1;
	display: grid;
	grid-template-columns: 1fr 1fr;
}

.splitScreenDivStyles {
	position: fixed;
}

.splitScreenDivStyles:nth-child(1) {
	width: 70vw !important;
	height: 100vh !important;
}

.splitScreenDivStyles:nth-child(2) {
	top: 76px;
	left: 70vw;

	width: 30vw !important;
	height: 100vh !important;
}

.controlsWrapper {
	width: 100%;
	height: 100%;
	overflow-y: scroll;
	padding: 16px;
	padding-bottom: 108px;
}

.controlsTitle {
	margin-bottom: 16px;
	margin-top: 24px;
}

.controlsSubtitle {
	margin-bottom: 8px;
	margin-top: 12px;
}

.controlsSelect {
	width: 100%;
	height: 32px;
}

.controlsSymbol {
	display: inline-block;
	margin-right: 8px;
	font-size: 24px;
	user-select: none;
	transition: transform 0.2s;
}

.controlsSymbol:hover {
	cursor: pointer;
	transform: scale(1.1);
}

.controlsCheckboxLabel,
.controlsCheckbox:hover {
	cursor: pointer !important;
}

.lettersDiamondToggles {
	margin-top: 8px;
}

.lettersWithDiamondsMenuButton {
	width: 100% !important;
}

.controlsPrice {
	float: right;
	text-transform: uppercase;
	font-weight: 900;
}

@media screen and (max-width: 768px) {
	.splitScreenDivStyles:nth-child(1) {
		width: 100vw !important;
		height: 50vh !important;
	}

	.splitScreenDivStyles:nth-child(2) {
		width: 100vw !important;
		height: 50vh !important;

		top: calc(50vh + 76px);
		left: 0;
	}
}
